<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb
                :showFilter="true"   
                :showDateFilter="true">
            </bread-crumb>
        </div>
        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                                :progress_class = "toprow.progress_class"
                                :progressbar_width = "toprow.progressbar_width"
                                :compared_text = "toprow.compared_text"
                            ></transactionstoprow-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Payouts List -->
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Payouts</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="search-transactions">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                            </div>
                            <!-- Add new user Buttons -->
                            <div class="d-md-flex justify-content-end">
                                <b-button variant="primary mt-2 mt-sm-0 btn-mw" size="lg" v-b-modal.payout_model>Add Record <i class="fa fa-plus-square ml-1"></i></b-button>
                                <b-modal id="payout_model" title="Add New Payout" hide-footer>
                                    <div class="body">
                                        <b-tabs content-class="px-0" nav-class="nav-tabs-new2" :no-nav-style="true">
                                            <b-tab class="transfer-tab" title="Bank" active>
                                                <div class="modal-area">
                                                    <ul class="list-unstyled mb-0 bank-model">
                                                        <ValidationObserver v-slot="{ passes }">
                                                            <form  id="bank-form" @submit.prevent="passes(bankFormSubmit)">
                                                                <li>
                                                                    <ValidationProvider name="country" rules="required" v-slot="{ errors}">
                                                                        <div class="form-group form-select single-multiselect country-dropdown" :class="{'errors': errors.length && !bankHolder.country,'success':bankHolder.country}">
                                                                            <span class="float-label">
                                                                                <label for="functions">Select Country*</label>
                                                                                <multiselect
                                                                                    placeholder=""
                                                                                    v-model="bankHolder.country"  
                                                                                    :show-labels="false" 
                                                                                    :options="bankHolderCountry_options" 
                                                                                    :searchable="false"
                                                                                    label="name" 
                                                                                    track-by="name" >
                                                                                    <template slot="option" slot-scope="props">
                                                                                        <div class="option-title">
                                                                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                            <span>{{ props.option.name }}</span>
                                                                                        </div>
                                                                                    </template>
                                                                                </multiselect> 
                                                                                <i class="fa fa-times" v-if="errors.length && !bankHolder.country"></i>
                                                                                <i class="fa fa-check" v-if="bankHolder.country"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="bankName" rules="required" v-slot="{ errors}"> 
                                                                        <div class="form-group" :class="{'errors': errors.length && !bankHolder.bankName,'success':bankHolder.bankName}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="bankHolder.bankName" class="form-control" id="bankName" placeholder="Bank Name*">
                                                                                <label for="bankName" class="control-label">Bank Name*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !bankHolder.bankName"></i>
                                                                                <i class="fa fa-check" v-if="bankHolder.bankName"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="accountName" rules="required" v-slot="{ errors}"> 
                                                                        <div class="form-group" :class="{'errors': errors.length && !bankHolder.accountName,'success':bankHolder.accountName}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="bankHolder.accountName" class="form-control" id="accountName" placeholder="Account Name*">
                                                                                <label for="accountName" class="control-label">Account Name*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !bankHolder.accountName"></i>
                                                                                <i class="fa fa-check" v-if="bankHolder.accountName"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="accountNumber" rules="required" v-slot="{ errors}" vid="confirmation">
                                                                        <div class="form-group" :class="{'errors': errors.length && !bankHolder.accountNumber,'success':bankHolder.accountNumber && !errors[0]}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="bankHolder.accountNumber" class="form-control" name="accountNumber" id="accountNumber" placeholder="Account Number*">
                                                                                <label for="accountNumber" class="control-label">Account Number*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !bankHolder.accountNumber "></i>
                                                                                <i class="fa fa-check" v-if="bankHolder.accountNumber && !errors[0]"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="confirm_accountNumber" rules="required|confirmed:confirmation" v-slot="{ errors}">
                                                                        <div class="form-group" :class="{'errors': errors.length && !bankHolder.confirm_accountNumber || errors[0],'success':bankHolder.confirm_accountNumber && !errors[0]}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="bankHolder.confirm_accountNumber" class="form-control" name="confirmation_password" id="confirm_accountNumber" placeholder="Confirm Account Number*">
                                                                                <label for="confirm_accountNumber" class="control-label">Confirm Account Number*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !bankHolder.confirm_accountNumber || errors[0] "></i>
                                                                                <i class="fa fa-check" v-if="bankHolder.confirm_accountNumber && !errors[0]"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="branchName" rules="required" v-slot="{ errors}"> 
                                                                        <div class="form-group" :class="{'errors': errors.length && !bankHolder.branchName,'success':bankHolder.branchName}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="bankHolder.branchName" class="form-control" id="branchName" placeholder="Branch Name*">
                                                                                <label for="branchName" class="control-label">Branch Name*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !bankHolder.branchName"></i>
                                                                                <i class="fa fa-check" v-if="bankHolder.branchName"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="purpose" rules="required" v-slot="{ errors}"> 
                                                                        <div class="form-group" :class="{'errors': errors.length && !bankHolder.purpose,'success':bankHolder.purpose}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="bankHolder.purpose" class="form-control" id="purpose" placeholder="Purpose*">
                                                                                <label for="purpose" class="control-label">Purpose*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !bankHolder.purpose"></i>
                                                                                <i class="fa fa-check" v-if="bankHolder.purpose"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                                                        <p class="mb-0">Make this PUBLIC</p>
                                                                        <div class="custom-switch success-bg">
                                                                            <input type="checkbox" class="custom-switch-input" id="customSwitch1">
                                                                            <label class="custom-switch-label" for="customSwitch1">
                                                                                <span class="custom-switch-text1">Yes</span>
                                                                                <span class="custom-switch-text2">No</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                        <b-button type="submit" variant="primary btn-mw" size="lg">Add Record</b-button>
                                                                        <b-button @click="$bvModal.hide('payout_model')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                                    </div>
                                                                </li>
                                                            </form>
                                                        </ValidationObserver>
                                                    </ul>
                                                </div>
                                            </b-tab>
                                            <b-tab title="Mobile">
                                                <div class="modal-area">
                                                    <ul class="list-unstyled mb-0 mobile-model">
                                                        <ValidationObserver v-slot="{ passes }">
                                                            <form  id="mobile-form" @submit.prevent="passes(mobileFormSubmit)">
                                                                <li>
                                                                    <ValidationProvider name="mNetowrkName" rules="required" v-slot="{ errors}">
                                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !mobileOprator.mNetowrkName,'success':mobileOprator.mNetowrkName}">
                                                                            <span class="float-label">
                                                                                <label for="functions">Choose Mobile Network*</label>
                                                                                <multiselect
                                                                                    placeholder=""
                                                                                    v-model="mobileOprator.mNetowrkName"  
                                                                                    :show-labels="false" 
                                                                                    :options="mobileNetwork_options" 
                                                                                    :searchable="false"
                                                                                    label="name" 
                                                                                    track-by="name"
                                                                                    :custom-label="networkWithCountry">
                                                                                    <template slot="option" slot-scope="props">
                                                                                        <div class="option-title">
                                                                                            <span class="mr-2">{{ props.option.name }}</span>
                                                                                            <span>({{ props.option.country }})</span>
                                                                                        </div>
                                                                                    </template>
                                                                                </multiselect> 
                                                                                <i class="fa fa-times" v-if="errors.length && !mobileOprator.mNetowrkName"></i>
                                                                                <i class="fa fa-check" v-if="mobileOprator.mNetowrkName"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="subscriberName" rules="required" v-slot="{ errors}"> 
                                                                        <div class="form-group" :class="{'errors': errors.length && !mobileOprator.subscriberName,'success':mobileOprator.subscriberName}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="mobileOprator.subscriberName" class="form-control" id="subscriberName" placeholder="Subscriber Name*">
                                                                                <label for="subscriberName" class="control-label">Subscriber Name*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !mobileOprator.subscriberName"></i>
                                                                                <i class="fa fa-check" v-if="mobileOprator.subscriberName"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="mobileNumber" rules="required" v-slot="{ errors}" vid="confirmation">
                                                                        <div class="form-group" :class="{'errors': errors.length && !mobileOprator.mobileNumber,'success':mobileOprator.mobileNumber && !errors[0]}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="mobileOprator.mobileNumber" class="form-control" name="mobileNumber" id="mobileNumber" placeholder="Mobile Number*">
                                                                                <label for="mobileNumber" class="control-label">Mobile Number*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !mobileOprator.mobileNumber "></i>
                                                                                <i class="fa fa-check" v-if="mobileOprator.mobileNumber && !errors[0]"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="confirm_mobileNumber" rules="required|confirmed:confirmation" v-slot="{ errors}">
                                                                        <div class="form-group" :class="{'errors': errors.length && !mobileOprator.confirm_mobileNumber || errors[0],'success':mobileOprator.confirm_mobileNumber && !errors[0]}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="mobileOprator.confirm_mobileNumber" class="form-control" name="confirmation_password" id="confirm_mobileNumber" placeholder="Confirm Mobile Number*">
                                                                                <label for="confirm_mobileNumber" class="control-label">Confirm Mobile Number*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !mobileOprator.confirm_mobileNumber || errors[0] "></i>
                                                                                <i class="fa fa-check" v-if="mobileOprator.confirm_mobileNumber && !errors[0]"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <ValidationProvider name="mnpurpose" rules="required" v-slot="{ errors}"> 
                                                                        <div class="form-group" :class="{'errors': errors.length && !mobileOprator.mnpurpose,'success':mobileOprator.mnpurpose}">
                                                                            <span class="float-label">
                                                                                <input type="text" v-model="mobileOprator.mnpurpose" class="form-control" id="mnpurpose" placeholder="Purpose*">
                                                                                <label for="mnpurpose" class="control-label">Purpose*</label>
                                                                                <div class="line"></div>
                                                                                <i class="fa fa-times" v-if="errors.length && !mobileOprator.mnpurpose"></i>
                                                                                <i class="fa fa-check" v-if="mobileOprator.mnpurpose"></i>
                                                                            </span>
                                                                        </div>
                                                                    </ValidationProvider>
                                                                </li>
                                                                <li>
                                                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                                                        <p class="mb-0">Make this PUBLIC</p>
                                                                        <div class="custom-switch success-bg">
                                                                            <input type="checkbox" class="custom-switch-input" id="customSwitch2">
                                                                            <label class="custom-switch-label" for="customSwitch2">
                                                                                <span class="custom-switch-text1">Yes</span>
                                                                                <span class="custom-switch-text2">No</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                        <b-button type="submit" variant="primary btn-mw" size="lg">Add Record</b-button>
                                                                        <b-button @click="$bvModal.hide('payout_model')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                                    </div>
                                                                </li>
                                                            </form>
                                                        </ValidationObserver>
                                                    </ul>
                                                </div>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </b-modal>
                            </div>
                            <!-- Toggle Filter Collapse -->
                            <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                <div class="d-flex justify-content-between p-3">
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Input One"/>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Input Two"/>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Input Three"/>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                        <b-table 
                            responsive
                            outlined
                            table-class="mb-0 table-custom"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(country_currency)="data">
                                <div class="align-items-center d-flex">
                                    <span class="mr-2">{{ data.value.currenacy }}</span>
                                    <span :class="data.value.flagClass" class="mr-2"></span>
                                    <span>{{ data.value.country }}</span>
                                </div>
                            </template>
                            <template #cell(actions)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
import '@/plugins/sweetalert2'
export default {
    name:'PayoutComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect'),
        'transactionstoprow-component': () => import('@/components/core/TransactionsTopRow.vue')
    },
    methods: {
        // Use sweetalert2
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },
        
        //go to pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        //
        bankFormSubmit(){
            console.log("bank data submited");
        },

        mobileFormSubmit(){
            console.log("mobile data submited");
        },

        networkWithCountry ({ name, country }) {
            return `${name}- (${country}) `
        },
    },data(){
        return{
            isVisibleFilter: false,
            //items
            items:[
                {
                    country_currency: {
                        currenacy:'GBP',
                        flagClass: "flag-icon flag-icon-gb fi-xl fi-round",
                        country:'United Kingdom'
                    },
                    bank_operator:'HSBC Holdings',
                    account_holder:'Jack Leo',
                    identiy_number:'20400952521459',
                    purpose:'General Transfers',
                    actions:'',
                },
                {
                    country_currency: {
                        currenacy:'USD',
                        flagClass: "flag-icon flag-icon-us fi-xl fi-round",
                        country:'United States'
                    },
                    bank_operator:'JPMorgan Chase & Co',
                    account_holder:'Smith Williams',
                    identiy_number:'89512348512325',
                    purpose:'General Transfers',
                    actions:'',
                },
                {
                    country_currency: {
                        currenacy:'CRE',
                        flagClass: "flag-icon flag-icon-gh fi-xl fi-round",
                        country:'Ghanaian Cedi'
                    },
                    bank_operator:'Airtel Ghana Limited',
                    account_holder:'Ababio Kojo',
                    identiy_number:'233-577553290',
                    purpose:'Telecommunication ',
                    actions:'',
                },
                {
                    country_currency: {
                        currenacy:'USD',
                        flagClass: "flag-icon flag-icon-us fi-xl fi-round",
                        country:'United States'
                    },
                    bank_operator:'T-Mobile US',
                    account_holder:'Brown Davis',
                    identiy_number:'933-574553181',
                    purpose:'Telecommunication ',
                    actions:'',
                },
                {
                    country_currency: {
                        currenacy:'CFP',
                        flagClass: "flag-icon flag-icon-fr fi-xl fi-round",
                        country:'French Polynesia'
                    },
                    bank_operator:'BNP Paribas',
                    account_holder:'Corentin Lucien',
                    identiy_number:'0500013M026',
                    purpose:'Economic Services ',
                    actions:'',
                },
            ],
           
            fields: [
                { key: 'country_currency', label: 'Country/Currency',sortable: true,thClass:'th_sortfix'},
                { key: 'bank_operator', label: 'Bank/Operator',sortable: true,thClass:'th_sortfix'},
                { key: 'account_holder', label: 'Account Holder',sortable: true,thClass:'th_sortfix'},
                { key: 'identiy_number', label: 'Identification Number',sortable: true,thClass:'th_sortfix'},
                { key: 'purpose', label: 'Purpose',sortable: true,thClass:'th_sortfix'},
                { key: 'actions', label: 'Actions'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            bankHolder:{
                country:'',
                accountName:'',
                bankName:'',
                accountNumber:'',
                confirm_accountNumber:'',
                branchName:'',
                purpose:'',
            },

            mobileNetwork_options: [
                { name: 'MTN', country:'GHANA'},
                { name: 'T-Mobile', country:'UNITED STATES'},
            ],

            bankHolderCountry_options: [
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'Ghana', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'France', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            mobileOprator:{
                mNetowrkName:'',
                subscriberName:'',
                mobileNumber:'',
                confirm_mobileNumber:'',
                mnpurpose:'',
            },

            //Summary data
            transactions_toprows:[
                {
                    icon:'fa fa-user-plus',
                    text:'New Members(today)',
                    price:'220',
                    progress_class:'bg-danger',
                    progressbar_width:'87',
                    compared_text:'1% compared to yesterday'
                },
                {
                    icon:'fa fa-calendar',
                    text:'New This Month',
                    price:'100',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    compared_text:'19% compared to last month'
                },
                {
                    icon:'fa-users',
                    text:'Total Member',
                    price:'3200',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    compared_text:'19% compared to last year'
                },
                {
                    icon:'fa fa-map-marker',
                    text:'Member Location',
                    price:'3000',
                    progress_class:'bg-warning',
                    progressbar_width:'75',
                    compared_text:'19% compared to last year'
                }
            ],
        }
    },mounted() {
        this.totalRows = this.items.length
    }
}
</script>
